import { computed, signal } from '@preact/signals';
import { v4 as uuid } from 'uuid';

import { deleteMapper } from '@/utils';
import { Client } from '@/types';
import { Timestamp } from 'firebase/firestore';

export const clientsSignal = signal<Client[]>([]);

export const activeClientsSignal = computed(() => {
  return clientsSignal.value
    .filter((c) => !c.deleted)
    .sort((a, b) => {
      if (a.lastName > b.lastName) return 1;
      if (a.lastName < b.lastName) return -1;
      return 0;
    });
});

export const clientsOnCreate = (entity: Client) => {
  clientsSignal.value = [...clientsSignal.value, entity];
};

export const clientsOnRemove = (entity: Client) => {
  clientsSignal.value = clientsSignal.value.map(deleteMapper(entity));
};

export const clientsOnUpdate = (entity: Partial<Client>) => {
  clientsSignal.value = clientsSignal.value.map((c) => {
    if (c.id === entity.id) return { ...c, ...entity };
    return c;
  });
};

export const validatePhoneNumber = (e: InputEvent, entityId: string) => {
  const el = e.target as HTMLInputElement;
  const val = el.value.replace(/\D/g, '').slice(-10);
  const numbers = clientsSignal.value
    .filter((client) => !client.deleted)
    .map((client) => [
      client.phoneNumber.replace(/\D/g, '').slice(-10),
      client.id,
    ]);

  if (val && numbers.find(([phone, id]) => val === phone && id !== entityId)) {
    el.setCustomValidity('Такий номер телефону вже існує');
  } else if (val) {
    el.setCustomValidity('');
  }
};

export const buildNewClient = (): Client => ({
  instagramUrl: '',
  phoneNumber: '',
  lastName: '',
  firstName: '',
  notes: '',
  deleted: false,
  id: uuid(),
  updatedAt: Timestamp.now(),
  createdAt: Timestamp.now(),
});
