import { signal } from '@preact/signals';
import { v4 as uuid } from 'uuid';

import { Appointment, Prices } from '@/types';
import { getMonth, getFullYear } from '@/utils';
import { AppointmentStatus, PaymentStatus } from '@/enums';

export const appointmentsSignal = signal<Appointment[]>([]);

export const appointmentsOnCreate = (entity: Appointment) => {
  appointmentsSignal.value = [...appointmentsSignal.value, entity];
};

export const appointmentsOnRemove = (entity: Partial<Appointment>) => {
  appointmentsSignal.value = appointmentsSignal.value.filter(
    (c) => c.id !== entity.id,
  );
};

export const appointmentsOnUpdate = (entity: Partial<Appointment>) => {
  appointmentsSignal.value = appointmentsSignal.value.map((c) => {
    if (c.id === entity.id) return { ...c, ...entity };
    return c;
  });
};

export const buildNewAppointment = (
  properties: Partial<Appointment>,
  params: { [K in keyof Partial<Appointment>]: string },
): Appointment => {
  const month = params.month !== undefined ? +params.month : getMonth();

  return {
    tips: 0,
    paymentStatus: PaymentStatus.NOT_PAID,
    status: AppointmentStatus.PENDING,
    clientId: (params.clientId !== 'PLACEHOLDER' && params.clientId) || '',
    procedureId: params.procedureId
      ? (JSON.parse(atob(params.procedureId)) as string[])
      : [],
    duration: params.duration ? +params.duration : 0,
    slot: (JSON.parse(params.slot || '[0, 0]') as [number, number]) || [0, 0],
    day: +(params.day || '') || 0,
    month,
    year: +(params.year || '') || getFullYear(),
    price1: params.price1 ? (JSON.parse(atob(params.price1)) as Prices) : {},
    price2: params.price2 ? (JSON.parse(atob(params.price2)) as Prices) : {},
    notes: params.notes || '',
    id: uuid(),
  };
};
