import { Router, Route, RouterOnChangeArgs } from 'preact-router';
import { render } from 'preact';
import { Link } from 'preact-router/match';

import Home from '@/routes/Home';
import Auth from '@/routes/Auth';
import Analytics from '@/routes/Analytics';
import Settings from '@/routes/Settings';

import ClientsIndex from '@/routes/ClientsIndex';
import ClientsEdit from '@/routes/ClientsEdit';
import ClientsNew from '@/routes/ClientsNew';
import ClientsAppointments from '@/routes/ClientsAppointments';
import ClientsSales from '@/routes/ClientsSales';

import ProceduresIndex from '@/routes/ProceduresIndex';
import ProceduresEdit from '@/routes/ProceduresEdit';
import ProceduresNew from '@/routes/ProceduresNew';

import ExpensesIndex from '@/routes/ExpensesIndex';
import ExpensesEdit from '@/routes/ExpensesEdit';
import ExpensesNew from '@/routes/ExpensesNew';

import AppointmentsEdit from '@/routes/AppointmentsEdit';
import AppointmentsNew from '@/routes/AppointmentsNew';

import ProductsIndex from '@/routes/ProductsIndex';
import ProductsBrands from '@/routes/ProductsBrands';
import ProductsNew from '@/routes/ProductsNew';
import ProcurementsIndex from '@/routes/ProcurementsIndex';
import ProductsEdit from '@/routes/ProductsEdit';

import SalesNew from '@/routes/SalesNew';
import SalesEdit from '@/routes/SalesEdit';

import Calendar4Week from '@/svgs/calendar4-week.svg';
import GraphUp from '@/svgs/graph-up.svg';
import Basket from '@/svgs/basket.svg';
import Gear from '@/svgs/gear.svg';
import People from '@/svgs/people.svg';
import CashStack from '@/svgs/cash-stack.svg';
import LayoutTextSidebarReverse from '@/svgs/layout-text-sidebar-reverse.svg';

import { userSignal } from '@/signals';

import '@/index.css';
import ProcurementsNew from './routes/ProcurementsNew';
import ProcurementsEdit from './routes/ProcurementsEdit';
import { settingsSignal } from './signals/settings';
import ProductsSales from './routes/ProductsSales';

const ProductsRouter = ({ matches }: { matches: Record<string, string> }) => {
  if (matches.brand) return <ProductsIndex />;
  return <ProductsBrands />;
};

const App = () => {
  const onChange = (e: RouterOnChangeArgs) => {
    if (!e.url.match('/auth') && !userSignal.value) {
      window.location = `/auth?back=${encodeURIComponent(
        e.url,
      )}` as unknown as Location;
    }
  };

  const navButtonClass = 'btn btn-sm btn-outline-secondary';

  return (
    <div class='App pb-5'>
      <div class='TapBar'>
        <Link href='/' data-locator='home' class={navButtonClass}>
          <Calendar4Week alt='Дім' width='20' />
        </Link>
        {settingsSignal.value?.analyticsEnabled && (
          <Link
            data-locator='analytics'
            class={navButtonClass}
            href='/analytics'
          >
            <GraphUp alt='Аналітика' width='20' />
          </Link>
        )}
        <span class='m-auto' />
        {settingsSignal.value?.expensesEnabled && (
          <Link data-locator='expenses' class={navButtonClass} href='/expenses'>
            <CashStack alt='Витрати' width='20' />
          </Link>
        )}
        <Link data-locator='clients' class={navButtonClass} href='/clients'>
          <People alt='Клієнти' width='20' />
        </Link>
        <Link
          data-locator='procedures'
          class={navButtonClass}
          href='/procedures'
        >
          <LayoutTextSidebarReverse alt='Процедури' width='20' />
        </Link>
        {settingsSignal.value?.productsEnabled && (
          <Link data-locator='products' class={navButtonClass} href='/products'>
            <Basket alt='Продукти' width='20' />
          </Link>
        )}
        <Link data-locator='settings' class={navButtonClass} href='/settings'>
          <Gear alt='Налаштування' width='20' />
        </Link>
      </div>

      <Router onChange={onChange}>
        <Route path='/' component={Home} />
        <Route path='/settings' component={Settings} />
        <Route path='/analytics' component={Analytics} />
        <Route path='/auth' component={Auth} />

        <Route
          path='/:entityType/:entityId/procurements'
          component={ProcurementsIndex}
        />
        <Route path='/procurements/new2' component={ProcurementsNew} />
        <Route
          path='/procurements/:procurementId/edit2'
          component={ProcurementsEdit}
        />

        <Route path='/clients' component={ClientsIndex} />
        <Route path='/clients/new' component={ClientsNew} />
        <Route
          path='/clients/:clientId/appointments'
          component={ClientsAppointments}
        />
        <Route path='/clients/:clientId/sales' component={ClientsSales} />
        <Route path='/clients/:clientId/edit' component={ClientsEdit} />

        <Route path='/expenses' component={ExpensesIndex} />
        <Route path='/expenses/new' component={ExpensesNew} />
        <Route path='/expenses/:expenseId/edit' component={ExpensesEdit} />

        <Route path='/procedures' component={ProceduresIndex} />
        <Route path='/procedures/new' component={ProceduresNew} />
        <Route
          path='/procedures/:procedureId/edit'
          component={ProceduresEdit}
        />

        <Route path='/appointments/new' component={AppointmentsNew} />
        <Route
          path='/appointments/:appointmentId/edit'
          component={AppointmentsEdit}
        />

        <Route path='/products' component={ProductsRouter} />
        <Route path='/products/new' component={ProductsNew} />
        <Route path='/products/:productId/edit' component={ProductsEdit} />
        <Route path='/products/:productId/sales' component={ProductsSales} />

        <Route path='/sales/new' component={SalesNew} />
        <Route path='/sales/:saleId/edit' component={SalesEdit} />
      </Router>
    </div>
  );
};

render(<App />, document.querySelector('.root')!);
