import type { Appointment, Expense, Procedure, Slot } from '@/types';
import { Brand, Product, ProductForSaleProcurement } from 'cosdb-types';

export const deleteMapper =
  <T extends { id: string }>(e: T) =>
  (c: T) =>
    c.id === e.id ? { ...c, deleted: true } : c;

export const getMonthOffset = (year: number, month: number) => {
  const day = new Date(year, month, 1).getDay();

  return {
    0: 6,
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
  }[day];
};

export const getDaysInMonth = (year: number, month: number) =>
  new Date(year, month + 1, 0).getDate();

export const padStart = (s: string) => {
  if (s.toString().length === 1) return `0${s}`;
  return s;
};

export const calculateTimespan = (slot: Slot, duration: number) => {
  const start = slotToString(slot);
  const end = slotToString([slot[0] + duration, slot[1] + duration]);

  return `${start} - ${end}`;
};

export const groupBy = <T extends Record<string, any>>(
  array: T[],
  key: string,
) => {
  type Acc = Record<string, T[]>;

  return array.reduce((acc, currentValue) => {
    const group = currentValue[key] as string;

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push(currentValue);

    return acc;
  }, {} as Acc);
};

export const sumBy = <T,>(array: T[], key: keyof T) => {
  return array.reduce((sum, currentValue) => sum + +currentValue[key], 0);
};

type _Window = Window &
  typeof globalThis & {
    __m: number;
    __y: number;
    __d: number;
  };

export const getMonth = () => {
  return (window as _Window).__m || new Date().getMonth();
};

export const getFullYear = () => {
  return (window as _Window).__y || new Date().getFullYear();
};

export const getDate = () => {
  return (window as _Window).__d || new Date().getDate();
};

export const expensesSorter = (a: Expense, b: Expense) => {
  if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) return 1;
  if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) return -1;
  return 0;
};

export const dateStorter = (
  a: { day: number; month: number; year: number },
  b: { day: number; month: number; year: number },
) => {
  const dateA = new Date(a.year, a.month - 1, a.day);
  const dateB = new Date(b.year, b.month - 1, b.day);

  if (dateA < dateB) return 1;
  if (dateA > dateB) return -1;
  return 0;
};

export const salesSorter = dateStorter;

export const procurementsSorter = dateStorter;

export const appointmentsSorter = (a: Appointment, b: Appointment) => {
  const toNumber = (i: Appointment) =>
    +(
      new Date(i.year, i.month - 1, i.day).getTime().toString() +
      ((i.slot[0] < 1000 ? '0' : '') + i.slot[0])
    );

  if (toNumber(a) < toNumber(b)) return 1;
  if (toNumber(a) > toNumber(b)) return -1;
  return 0;
};

export const proceduresSorter = (a: Procedure, b: Procedure) => {
  if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) return 1;
  if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) return -1;
  return 0;
};

export const productsSorter = (a: Product, b: Product) => {
  if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) return 1;
  if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) return -1;
  return 0;
};

export const brandsSorter = (a: Brand, b: Brand) => {
  if (a.value > b.value) return 1;
  if (a.value < b.value) return -1;
  return 0;
};

export const daysBetween = (
  day1: number,
  month1: number,
  day2 = getDate(),
  month2 = getMonth() + 1,
) => {
  if (!day1 || !month1) return Infinity;

  const year = getFullYear();
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(
    (new Date(year, month1, day1).getTime() -
      new Date(year, month2, day2).getTime()) /
      oneDay,
  );

  return diffDays;
};

export const last = <T,>(arr: T[]): T => {
  return arr[arr.length - 1];
};

export const isRangeInRanges = (ranges: Slot[], targetRange: Slot) => {
  for (let i = 0; i < ranges.length; i++) {
    const [rangeStart, rangeEnd] = ranges[i];
    const [targetStart, targetEnd] = targetRange;

    if (targetStart >= rangeStart && targetEnd <= rangeEnd) {
      return true;
    }
  }
  return false;
};

export const subtractRange = (ranges: Slot[], subtract: Slot) => {
  const result: Slot[] = [];

  for (let i = 0; i < ranges.length; i++) {
    const [start, end] = ranges[i];
    const [subStart, subEnd] = subtract;

    if (subStart > end || subEnd < start) {
      // No overlap
      result.push(ranges[i]);
    } else {
      // Overlap exists, handle four cases
      if (start < subStart) {
        result.push([start, Math.min(end, subStart)]);
      }
      if (end > subEnd) {
        result.push([Math.max(start, subEnd), end]);
      }
    }
  }

  return result;
};

export const mergeRanges = (args: Slot[]): Slot[] => {
  // console.log(args);
  const ranges = args.map((a) => [...a]) as Slot[];

  // Sort the ranges based on their start values
  ranges.sort((a, b) => a[0] - b[0]);

  const mergedRanges: Slot[] = [];
  let currentRange: Slot = ranges[0];

  for (let i = 1; i < ranges.length; i++) {
    const range: Slot = ranges[i];

    // Check if the current range overlaps with the next range
    if (currentRange[1] >= range[0]) {
      // Merge the ranges by updating the end value of the current range
      currentRange[1] = Math.max(currentRange[1], range[1]);
    } else {
      // The current range doesn't overlap with the next range, so add it to the merged ranges
      mergedRanges.push(currentRange);
      currentRange = range;
    }
  }

  // Add the last range to the merged ranges
  mergedRanges.push(currentRange);

  return mergedRanges;
};

export const slotToString = (slot: Slot) => {
  const hours = Math.floor(slot[0] / 60);
  const minutes = slot[0] % 60;

  return `${padStart(hours.toString())}:${padStart(minutes.toString())}`;
};

export const onlyLettersAndDigits = (phone: string) => {
  return phone
    .replace(/[^a-zA-Z0-9\u00C0-\u00FF\u0100-\u017F\u0400-\u04FF]/g, '')
    .toLowerCase();
};

export const getOldestProcurement = (
  procurements: ProductForSaleProcurement[],
) => {
  const res = [...procurements].sort(procurementsSorter);

  return res[res.length - 1];
};
