export enum RequestStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  IS_DONE = 'IS_DONE',
  DEFAULT = 'DEFAULT',
}

export enum Mode {
  REGULAR = 'REGULAR',
  SET_BREAK = 'SET_BREAK',
  SET_DAYS = 'SET_DAYS',
}

export enum AppointmentStatus {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export enum PaymentStatus {
  NOT_PAID = 'NOT_PAID',
  PAID_BY_CASH = 'PAID_BY_CASH',
  PAID_BY_CARD = 'PAID_BY_CARD',
}
