import { signal } from '@preact/signals';
import { getDate, getFullYear, getMonth } from '@/utils';
import { v4 as uuid } from 'uuid';
import { Procurement, ProcurementType } from 'cosdb-types';

export const procurementsSignal = signal<Procurement[]>([]);

export const procurementsOnCreate = (entity: Procurement) => {
  procurementsSignal.value = [...procurementsSignal.value, entity];
};

export const procurementsOnRemove = (entity: Partial<Procurement>) => {
  procurementsSignal.value = procurementsSignal.value.filter(
    (c) => c.id !== entity.id,
  );
};

export const procurementsOnUpdate = (entity: Partial<Procurement>) => {
  procurementsSignal.value = procurementsSignal.value.map((c) => {
    if (c.id === entity.id) return { ...c, ...entity };
    return c;
  });
};

export const buildNewProcurement = (
  properties: Partial<Procurement>,
  params: { [K in keyof Partial<Procurement>]: string },
): Procurement => ({
  entityId: params.entityId || '',
  day: +(params.day || '') || getDate(),
  month: +(params.month || '') || getMonth(),
  year: +(params.year || '') || getFullYear(),
  amount: +(params.amount || '') || 0,
  price: +(params.price || '') || 0,
  type: (params.type ? params.type : '') as ProcurementType,
  id: uuid(),
});
