import { signal } from '@preact/signals';
import { Product } from 'cosdb-types';
import { v4 } from 'uuid';

export const productsSignal = signal<Product[]>([]);

export const getProductById = (id?: string) =>
  productsSignal.value.find((_p) => _p.id === id);

export const buildNewProduct = ({ brandId }: Partial<Product>): Product => ({
  id: v4(),
  brandId: brandId || '',
  name: '',
  description: '',
  deleted: false,
  price: 0,
  inventory: {},
});
