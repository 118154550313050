import { v4 as uuid } from 'uuid';
import { signal } from '@preact/signals';

import { deleteMapper } from '@/utils';
import { Procedure } from '@/types';

export const proceduresSignal = signal<Procedure[]>([]);

export const proceduresOnCreate = (entity: Procedure) => {
  proceduresSignal.value = [...proceduresSignal.value, entity];
};

export const proceduresOnRemove = (entity: Procedure) => {
  proceduresSignal.value = proceduresSignal.value.map(deleteMapper(entity));
};

export const proceduresOnUpdate = (entity: Partial<Procedure>) => {
  proceduresSignal.value = proceduresSignal.value.map((c) => {
    if (c.id === entity.id) return { ...c, ...entity };
    return c;
  });
};

export const buildNewProcedure = (): Procedure => ({
  name: '',
  description: '',
  price1: 0,
  price2: 0,
  duration: 0,
  deleted: false,
  id: uuid(),
});
