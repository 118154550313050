import { signal } from '@preact/signals';
import { v4 } from 'uuid';
import { getDate, getFullYear, getMonth } from '@/utils';
import { Sale } from 'cosdb-types';

export const salesSignal = signal<Sale[]>([]);

export const salesOnCreate = (entity: Sale) => {
  salesSignal.value = [...salesSignal.value, entity];
};

export const salesOnRemove = (entity: Partial<Sale>) => {
  salesSignal.value = salesSignal.value.filter((c) => c.id !== entity.id);
};

export const salesOnUpdate = (entity: Partial<Sale>) => {
  salesSignal.value = salesSignal.value.map((c) => {
    if (c.id === entity.id) return { ...c, ...entity };
    return c;
  });
};

export const buildNewSale = (params: {
  [K in keyof Partial<Sale>]: string;
}): Sale => ({
  clientId: params.clientId || '',
  productId: params.productId || '',
  day: +(params.day || '') || getDate(),
  month: +(params.month || '') || getMonth(),
  year: +(params.year || '') || getFullYear(),
  inventory: {},
  price: +(params.price || '') || 0,
  id: v4(),
});
