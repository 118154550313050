import { v4 as uuid } from 'uuid';
import { signal } from '@preact/signals';

import { Expense } from '@/types';

export const expensesSignal = signal<Expense[]>([]);

export const expensesOnCreate = (entity: Expense) => {
  expensesSignal.value = [...expensesSignal.value, entity];
};

export const expensesOnRemove = ({ id }: Partial<Expense>) => {
  expensesSignal.value = expensesSignal.value.filter((i) => i.id !== id);
};

export const expensesOnUpdate = (entity: Partial<Expense>) => {
  expensesSignal.value = expensesSignal.value.map((c) => {
    if (c.id === entity.id) return { ...c, ...entity };
    return c;
  });
};

export const buildNewExpense = (): Expense => ({
  id: uuid(),
  notes: '',
  name: '',
});
